<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    payee: {
      name: { required },
      person_type: { required },
      person_number: { required },
      bank: { required },
      agency: { required },
      agency_digit: {},
      account: { required },
      account_digit: { required },
      account_type: { required },
    }
  },
  data() {
    return {
      bank: {
        code: '',
        label: '',
      },
      banks: [],

      payee: {
        loading: false,

        name: "",
        person_type: 1,
        person_number: "",
        bank: "",
        agency: "",
        agency_digit: "",
        account: "",
        account_digit: "",
        account_type: 1,
      }
    };
  },
  methods: {
    getBancos() {
      api.get("favorecidos/bancos").then((response) => {
        if (response.data.status == "success") {
          this.banks = response.data.list;
        }
      })
    },
    cadastrarFavorecido() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.payee.loading = true;

        api
          .post("favorecidos", {
            name: this.payee.name,
            person_type: this.payee.person_type,
            person_number: this.payee.person_number,
            bank: this.payee.bank,
            agency: this.payee.agency,
            agency_digit: this.payee.agency_digit,
            account: this.payee.account,
            account_digit: this.payee.account_digit,
            account_type: this.payee.account_type,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.payee.name = "";
              this.payee.person_type = "";
              this.payee.person_number = "";
              this.payee.bank = "";
              this.payee.agency = "";
              this.payee.agency_digit = "";
              this.payee.account = "";
              this.payee.account_digit = "";
              this.payee.account_type = "";
              this.$v.$reset();

              this.$router.push('/favorecidos');
              this.$toast.success('O favorecido foi cadastrado com sucesso.');
            } else {
              this.$toast.error(response.data.message);
            }

            this.payee.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getBancos();
  },
  watch: {
    person_type: function () {
      this.person_number = "";
    },
    'bank': function (v) {
      this.payee.bank = v.code
    },
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/favorecidos">Favorecidos</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Cadastrar favorecido</li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Cadastrar favorecido</h3>
        <p>Cadastre um favorecido para realizar seu pagamento.</p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-5">
            <b-form @submit.prevent="cadastrarFavorecido">
              <b-form-group id="name" label="Nome Completo" label-for="name">
                <b-form-input id="name" v-model="payee.name" type="text" :class="{ 'is-invalid': $v.payee.name.$error }"></b-form-input>
                <div v-if="$v.payee.name.$error" class="invalid-feedback">
                  <span v-if="!$v.payee.name.required">O nome completo é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group id="person_type" label="Tipo de Pessoa" label-for="person_type">
                <select v-model="payee.person_type" class="form-control" :class="{ 'is-invalid': $v.payee.person_type.$error }">
                  <option value="1">Pessoa Física</option>
                  <option value="2">Pessoa Jurídica</option>
                </select>
                <div v-if="$v.payee.person_type.$error" class="invalid-feedback">
                  <span v-if="!$v.payee.person_type.required">O tipo de pessoa é obrigatório.</span>
                </div>
              </b-form-group>
              <template v-if="payee.person_type == 1">
                <b-form-group id="person_number" label="CPF" label-for="person_number">
                  <b-form-input id="person_number" v-model="payee.person_number" type="text" :class="{ 'is-invalid': $v.payee.person_number.$error }" v-mask="'###.###.###-##'"></b-form-input>
                  <div v-if="$v.payee.person_number.$error" class="invalid-feedback">
                    <span v-if="!$v.payee.person_number.required">O cpf é obrigatório.</span>
                  </div>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group id="person_number" label="CNPJ" label-for="person_number">
                  <b-form-input id="person_number" v-model="payee.person_number" type="text" :class="{ 'is-invalid': $v.payee.person_number.$error }" v-mask="'##.###.###/####-##'"></b-form-input>
                  <div v-if="$v.payee.person_number.$error" class="invalid-feedback">
                    <span v-if="!$v.payee.person_number.required">O cnpj é obrigatório.</span>
                  </div>
                </b-form-group>
              </template>
              <b-form-group id="bank" label="Banco" label-for="bank">
                <v-select v-model="bank" :options="banks"></v-select>
                <div v-if="$v.payee.bank.$error" class="invalid-feedback">
                  <span v-if="!$v.payee.bank.required">O banco é obrigatório.</span>
                </div>
              </b-form-group>
              <div class="row">
                <div class="col-8">
                  <b-form-group id="bank" label="Agência" label-for="bank">
                    <b-form-input id="bank" v-model="payee.agency" type="text" :class="{ 'is-invalid': $v.payee.agency.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="bank" label="Digito" label-for="bank">
                    <b-form-input id="bank" v-model="payee.agency_digit" type="text" :class="{ 'is-invalid': $v.payee.agency_digit.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="$v.payee.agency.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.agency.required">A agência é obrigatório.</span>
                </div>
                <div v-if="$v.payee.agency_digit.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.agency_digit.required">O digito é obrigatório.</span>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <b-form-group id="bank" label="Conta" label-for="bank">
                    <b-form-input id="bank" v-model="payee.account" type="text" :class="{ 'is-invalid': $v.payee.account.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group id="bank" label="Digito" label-for="bank">
                    <b-form-input id="bank" v-model="payee.account_digit" type="text" :class="{ 'is-invalid': $v.payee.account_digit.$error }"></b-form-input>
                  </b-form-group>
                </div>
                <div v-if="$v.payee.account.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.account.required">A conta é obrigatório.</span>
                </div>
                <div v-if="$v.payee.account_digit.$error" class="col-12 invalid-feedback">
                  <span v-if="!$v.payee.account_digit.required">O digito é obrigatório.</span>
                </div>
              </div>
              <b-form-group id="account_type" label="Tipo da Conta" label-for="account_type">
                <select v-model="payee.account_type" class="form-control" :class="{ 'is-invalid': $v.payee.account_type.$error }">
                  <option value="1">Conta Corrente</option>
                  <option value="2">Conta Poupança</option>
                  <option value="3">Conta Pagamento</option>
                  <option value="4">Conta Salário</option>
                </select>
                <div v-if="$v.payee.account_type.$error" class="invalid-feedback">
                  <span v-if="!$v.payee.account_type.required">O tipo de conta é obrigatório.</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="this.payee.loading == true || this.$v.$invalid" type="submit" variant="default">
                  Adicionar
                  <b-spinner v-if="payee.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>